import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    // icon: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

export const ROUTES: RouteInfo[] = [/*{
    path: '/dashboard',
    title: 'Ventas',
    type: 'link',
    icontype: 'nc-icon nc-bank'
}, */{
    path: '/administracion',
    title: 'ADMINISTRACION',
    type: 'sub',
    collapse: 'admin',
    icontype: 'nc-icon nc-briefcase-24',
    children: [
        { path: 'orden-compras-administracion', title: 'ORDENES', ab: 'AOS' },
        { path: 'producto', title: 'PRODUCTOS', ab: 'APR' }
    ]
},
{
    path: '/compras',
    title: 'LOGÍSTICA',
    type: 'sub',
    collapse: 'cotizar',
    icontype: 'nc-icon nc-basket',
    children: [
        { path: 'requerimientos', title: 'REQUERIMIENTOS', ab: 'CRQ' },
        { path: 'cotizaciones-general', title: 'COTIZACIONES', ab: 'CCC' },
        { path: 'orden-compras-general', title: 'ORDENES', ab: 'COG' },
        { path: 'proveedor', title: 'PROVEEDORES', ab: 'CPR' }
    ]
},{
    path: '/tesoreria',
    title: 'TESORERIA',
    type: 'sub',
    collapse: 'tesoreria',
    icontype: 'nc-icon nc-money-coins',
    children: [
        { path: 'orden-compras-tesoreria', title: 'ORDENES', ab: 'TES' }
    ]
},{
    path: '/almacen',
    title: 'ALMACEN',
    type: 'sub',
    collapse: 'almacen',
    icontype: 'nc-icon nc-bank',
    children: [
        { path: 'ordenes', title: 'ORDENES DE COMPRAS', ab: 'AOC' },
        { path: 'stock-productos', title: 'STOCK - PRODUCTOS', ab: 'ALS' }
    ]
},{
    path: '/administracion',
    title: 'HERRAMIENTAS',
    type: 'sub',
    collapse: 'reque',
    icontype: 'nc-icon nc-settings-gear-65',
    children: [
        { path: 'usuario', title: 'USUARIO', ab: 'AUS' },
        { path: 'empresa-admin', title: 'EMPRESA', ab: 'AEM' },
        { path: 'establecimiento-admin', title: 'ALMACENES', ab: 'AES' },
        { path: 'unidades', title: 'UNIDADES', ab: 'ATD' },
        { path: 'extra', title: 'EXTRA', ab: 'APR' },
        { path: 'categoria', title: 'CATEGORÍA', ab: 'ACT' }
    ]
},
{
    path: '/',
    title: 'VENTAS',
    type: 'sub',
    collapse: 'ventas',
    icontype: 'nc-icon nc-cart-simple',
    children: [
        { path: 'ventas', title: 'VENTAS', ab: 'VVE' },
        { path: 'cotizacion', title: 'COTIZACIONES', ab: 'VVC' },
    ]
},
{
    path: '/reportes',
    title: 'REPORTES',
    type: 'sub',
    collapse: 'reportes',
    icontype: 'nc-icon nc-paper',
    children: [
        //{ path: 'ingreso-year', title: 'INGRESOS POR AÑO', ab: 'RIY' },
        //{ path: 'ingreso-meses', title: 'INGRESOS POR MESES', ab: 'RIM' },
        //{ path: 'ingreso-semanales', title: 'INGRESOS POR SEMANAS', ab: 'RIS' },
        { path: 'diarios', title: 'VENTAS DÍA - PRODUCTOS', ab: 'RVD' },
        { path: 'diario-comprobante', title: 'VENTAS DÍA - COMPROBANTES', ab: 'RVC' },
        { path: 'diarios-detallado-productos', title: 'VENTAS DÍA - PRODUCTOS DETALLADO', ab: 'RVD' },
        //{ path: 'usuario', title: 'VENTAS POR USUARIO', ab: 'RVU' },
    ]
}
];

@Component({
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent {
    public menuItems: any[];
    constructor(private router: Router) { }
    isNotMobileMenu() {
        if (window.outerWidth > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    ngAfterViewInit() {
    }
    finalizar() {
        localStorage.clear();
        this.router.navigate(['/login']);
    }
}
