import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';
import { UsuarioService } from './usuario.service'

@Injectable()
export class TesoreriaService {
    public url: string;
    public identity: any;
    public token: any;
    public verify: any;
    public url_interna: any;
    public url_unidades: any
    constructor(private _http: HttpClient, private _usuSer: UsuarioService) {
        this.url = GLOBAL.url;
        this.url_interna = '/tesoreria/tesoreria/';
    }
    obtenerIndexEstado(objecto: any, filtro: any): any {
        return objecto.findIndex(a => a.id == filtro);
    }

    //Componente ORDEN
    obtenerOrdenes(page = null, proveedor: any, orden:any, cotizacion: any, mes: any, year: any, empresa: any, estado: any, vendedor: any, orden_general: any) {
        if(page == null){
        page  = 1;
        }
        if(orden == null){
        orden  = 0;
        }
        if(cotizacion == null){
        cotizacion  = 0;
        }
        if(mes == null){
        mes  = 0;
        }
        if(year == null){
        year  = 0;
        }
        if(empresa == null){
        empresa  = 0;
        }
        if(estado == null){
        estado  = 0;
        }
        if(vendedor == null){
        vendedor  = 0;
        }
        if(orden_general == null){
        orden_general  = 0;
        }
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
            });
        return this._http.get(this.url + this.url_interna + 'obtenerOrdenes/'+page+'/'+proveedor+'/'+orden+'/'+cotizacion+'/'+mes+'/'+year+'/'+empresa+'/'+estado+'/'+vendedor+'/'+orden_general,{headers});
    }
    //Componente Orden
    actualizarOrdenGeneral(enviar) {
        let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_interna + 'actualizarEstadoOrdenes', enviar, { headers: headers });
    }
    //Componente Orden
    pagarEnviarDatosPagosOrdenes(enviar) {
        let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_interna + 'pagarEnviarDatosPagosOrdenes', enviar, { headers: headers });
    }
}