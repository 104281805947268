import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';
import { UsuarioService } from './usuario.service'

@Injectable()
export class ProductosService {
    public url: string;
    public identity: any;
    public token: any;
    public verify: any;
    public urlInterna: any;
    constructor(private _http: HttpClient, private _usuSer: UsuarioService) {
        this.url = GLOBAL.url;
        this.urlInterna = '/productos/producto/';
    }
    obtenerIndex(objecto: any, filtro: any): number {
        return objecto.findIndex(a => a.descripcion === filtro);
    }
    obtenerProductos(page = null) {
        if(page == null){
            page  = 1;
          }
		let params = new HttpParams();
		params = params.append('empresa_id', 'id');
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.get(this.url + this.urlInterna + 'obtenerProductos/'+page, { headers: headers });
    }
    registrarProducto(model: any) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + this.urlInterna + 'registrarProducto', model, { headers: headers });
    }
    actualizarProducto(modelo: any) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + this.urlInterna + 'actualizarProducto', modelo, { headers: headers });
    }
	obtenerProductosUnidadesHome(id: any) {
		let params = new HttpParams();
		params = params.append('id', id);
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + this.urlInterna +  'obtenerProductosUnidadesHome', params, { headers: headers });
	}
    registrarProductoUnidadesHome(modelo: any) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + this.urlInterna +  'registrarProductoUnidadesHome', modelo, { headers: headers });
	}
    actualizarProductoUnidadesHome(modelo: any) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + this.urlInterna +   'actualizarProductoUnidadesHome', modelo, { headers: headers });
    }
    eliminarProductoPrincipal(id: any) {
		let params = new HttpParams();
		params = params.append('id_producto', id);
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + this.urlInterna + 'eliminarProductoGeneral', params, { headers: headers });
    }
    obtenerCategoria() {
		let params = new HttpParams();
		params = params.append('0', '0');
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + this.urlInterna + 'obtenerCategoria', params, { headers: headers });
    }
    crudCategoria(modelo: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.urlInterna +   'crudCategorias', modelo, { headers: headers });
    }
    eliminarCategoriaGeneral(id: any) {
        let params = new HttpParams();
        params = params.append('id', id);
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.urlInterna + 'eliminarCategoriaGeneral', params, { headers: headers });
    }
    obtenerUnidad() {
        let params = new HttpParams();
        params = params.append('0', '0');
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.urlInterna + 'obtenerUnidad', params, { headers: headers });
    }
    crudUnidad(modelo: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.urlInterna +   'crudUnidad', modelo, { headers: headers });
    }
    eliminarUnidadGeneral(id: any) {
        let params = new HttpParams();
        params = params.append('id', id);
        let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.urlInterna + 'eliminarUnidadGeneral', params, { headers: headers });
    }
    obtenerExtra() {
        let params = new HttpParams();
        params = params.append('0', '0');
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.urlInterna + 'obtenerExtra', params, { headers: headers });
    }
    crudExtra(modelo: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.urlInterna +   'crudExtra', modelo, { headers: headers });
    }
    eliminarExtraGeneral(id: any) {
        let params = new HttpParams();
        params = params.append('id', id);
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.urlInterna + 'eliminarExtraGeneral', params, { headers: headers });
    }
    buscarProductos(id: any) {
        let params = new HttpParams();
        params = params.append('buscar', id);
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.urlInterna + 'buscarProductos', params, { headers: headers });
    }
}