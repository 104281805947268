import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';
import { UsuarioService } from './usuario.service'
import { Reqstorage } from '../model/reqstorage';
import { StorageCotizacionProducto } from '../model/storageCotizacionProducto';

@Injectable()
export class AlmacenService {
    public url: string;
    public identity: any;
    public token: any;
    public verify: any;
    public urlOrdenes: any;
    constructor(private _http: HttpClient, private _usuSer: UsuarioService) {
        this.url = GLOBAL.url;
        this.urlOrdenes = '/almacen/almacen/';
    }
    obtenerIndex(objecto: any, filtro: any): number {
        return objecto.findIndex(a => a.descripcion === filtro);
    }

    //COMPONENTE ORDENES
    obtenerOrdenesAlmacen(page = null) {
        if(page == null){
        page  = 1;
        }
        let headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': this._usuSer.getToken()
          });
    return this._http.get(this.url + this.urlOrdenes + 'obtenerOrdenesAlmacen/'+page,{headers});
  }

  guardarAlmacenIngresoOrdenCompraAutomatico(enviar) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.urlOrdenes + 'guardarAlmacenIngresoOrdenCompraAutomatico', enviar, { headers: headers });
  }
  obtenerReporteProductoStock(enviar) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.urlOrdenes + 'obtenerReporteProductoStock', enviar, { headers: headers });
  }
  obtenerEmpresa() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.urlOrdenes + 'obtenerEmpresa','', { headers: headers });
  }
}