import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';

@Injectable()
export class UsuarioService {

	public url: string;
	public token: any;
	constructor(private _http: HttpClient) {
		this.url = GLOBAL.url;
	}
	login(user_to_login: any, gettoken = null) {
		if (gettoken != null) {
			user_to_login.gettoken = gettoken;
		}
		let params = JSON.stringify(user_to_login);
		let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return this._http.post(this.url + '/login/auth/login', params, { headers: headers });
	}
	getToken() {
		let token = localStorage.getItem('token');
		if (token != "undefined") {
			this.token = token;
		} else {
			this.token = null;
		}
		return this.token;
	}
	obtenerUsuario() {
		let params = new HttpParams();
		params = params.append('lorem', 'lorem');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this.getToken()
		});
		return this._http.post(this.url + '/usuario/usuario/obtenerDatosUsuario', params, { headers: headers });
	}
	crudUsuario(modelo: any) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this.getToken()
		});
		return this._http.post(this.url + '/usuario/usuario/crudUsuario', modelo, { headers: headers });
	}
	obtenerIndexEmpresa(objecto: any, filtro: any): any {
        return objecto.findIndex(a => a.id_empresa == filtro);
	}
	obtenerIndexEstado(objecto: any, filtro: any): any {
        return objecto.findIndex(a => a.id == filtro);
    }
}