import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';
import { UsuarioService } from './usuario.service'
import { Reqstorage } from '../model/reqstorage';
import { StorageCotizacionProducto } from '../model/storageCotizacionProducto';
@Injectable()
export class CompraService {
    public url: string;
    public identity: any;
    public token: any;
    public verify: any;
    public urlInterna: any;
    public cotizacion_url: any;
    public orden_url: any;
    requeriminetos: Reqstorage[] = [];
    cotizacion: StorageCotizacionProducto[] = [];
    public proveedor_url;
    constructor(private _http: HttpClient, private _usuSer: UsuarioService) {
        this.url = GLOBAL.url;
        this.urlInterna = '/compras/requerimiento/';
        this.cotizacion_url = '/compras/cotizacion/';
        this.orden_url = '/compras/orden/';
        this.proveedor_url = '/compras/proveedor/';
    }
    obtenerIndex(objecto: any, filtro: any): number {
        return objecto.findIndex(a => a.descripcion === filtro);
    }
    obtenerIndexComprobante(objecto: any, filtro: any): number {
      return objecto.findIndex(a => a.id === filtro);
  }
    obtenerIndexEmpresa(objecto: any, filtro: any): number {
      return objecto.findIndex(a => a.id_empresa == filtro);
  }
  //Componente Requerimiento
  obtenerRequerimiento(page = null, env: any, estado:any, ordenes: any, requerimiento: any) {
    if(page == null){
      page  = 1;
    }
    if(env == null){
      env  = 0;
    }
    if(estado == null){
      estado  = 0;
    }
    if(ordenes == null){
      ordenes  = 0;
    }
    if(requerimiento == null){
      requerimiento  = 0;
    }
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
      });
		return this._http.get(this.url + this.urlInterna + 'obtenerRequerimiento/'+page+'/'+env+'/'+estado+'/'+ordenes+'/'+requerimiento,{headers});
  }
	buscarProductosVentasRequerimientos(buscar,des) {
		let params = new HttpParams();
    params = params.append('buscar', buscar);
    params = params.append('des', des);
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + this.urlInterna + 'buscarProductosVentasRequerimientos', params, { headers: headers });
	}
  //Componente Requerimiento
	getProducto() {
		if (localStorage.getItem('productos_requerimientos_inicio') === null) {
		  this.requeriminetos = [];
		} else {
		  this.requeriminetos = JSON.parse(localStorage.getItem('productos_requerimientos_inicio'));
		}
		return this.requeriminetos;
	}
  //Componente Requerimiento
	addProducto(requerimiento: Reqstorage) {
		this.requeriminetos.push(requerimiento);
		let productos = [];
		if (localStorage.getItem('productos_requerimientos_inicio') === null) {
		  productos = [];
		  productos.push(requerimiento);
		  localStorage.setItem('productos_requerimientos_inicio', JSON.stringify(productos));
		} else {
		  productos = JSON.parse(localStorage.getItem('productos_requerimientos_inicio'));
		  productos.push(requerimiento);
		  localStorage.setItem('productos_requerimientos_inicio', JSON.stringify(productos));
		}
  }
  //Componente Requerimiento
	deleteProducto(producto: Reqstorage) {
		for (let i = 0; i < this.requeriminetos.length; i++) {
		  if (producto === this.requeriminetos[i]) {
			this.requeriminetos.splice(i, 1);
			localStorage.setItem('productos_requerimientos_inicio', JSON.stringify(this.requeriminetos));
		  }
		}
	}
  //Componente Requerimiento
	obtenerResponder(enviar) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + this.urlInterna + 'obtenerResponder', enviar, { headers: headers });
  }
  actualizarProductosRequerimientos(enviar) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.urlInterna + 'actualizarProductosRequerimientos', enviar, { headers: headers });
  }
  //Componente Requerimiento
  agregarRequerimiento(enviar) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.urlInterna + 'agregarRequerimiento', enviar, { headers: headers });
  }
  agregarAutomaticoSinCotizacionSinOrdenCompra(enviar) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.urlInterna + 'agregarAutomaticoSinCotizacionSinOrdenCompra', enviar, { headers: headers });
  }
  //Componente Cotización
  actualizarRequerimientoEstado(enviar) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.cotizacion_url + 'actualizarRequerimientoEstado', enviar, { headers: headers });
  }
  //Componente Cotización
   actualizarRequerimientoProductoEstado(enviar) {
  let headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this._usuSer.getToken()
  });
  return this._http.post(this.url + this.cotizacion_url + 'actualizarRequerimientoProductoEstado', enviar, { headers: headers });
  }
  //Componente Cotización
  obtenerCotizacion(enviar) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + this.urlInterna + 'obtenerCotizacion', enviar, { headers: headers });
  }
  //Componente Cotizacion
  obtenerCotizacionRequerimiento(model: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.cotizacion_url + 'obtenerCotizacion', model, { headers: headers });
  }
  //Componente Cotizacion
	getCotizacionProducto() {
		if (localStorage.getItem('cotizacion') === null) {
		  this.cotizacion = [];
		} else {
		  this.cotizacion = JSON.parse(localStorage.getItem('cotizacion'));
		}
		return this.cotizacion;
	}
  //Componente Cotizacion
	addCotizacionProducto(cotizaciones: StorageCotizacionProducto) {
		this.cotizacion.push(cotizaciones);
		let cotizacion = [];
		if (localStorage.getItem('cotizacion') === null) {
		  cotizacion = [];
		  cotizacion.push(cotizaciones);
		  localStorage.setItem('cotizacion', JSON.stringify(cotizacion));
		} else {
		  cotizacion = JSON.parse(localStorage.getItem('cotizacion'));
		  cotizacion.push(cotizaciones);
		  localStorage.setItem('cotizacion', JSON.stringify(cotizacion));
		}
  }
  //Componente Cotizacion
	deleteCotizacionProducto(cotizaciones: StorageCotizacionProducto) {
		for (let i = 0; i < this.cotizacion.length; i++) {
		  if (cotizaciones === this.cotizacion[i]) {
			this.cotizacion.splice(i, 1);
			localStorage.setItem('cotizacion', JSON.stringify(this.cotizacion));
		  }
		}
	}
  //Componente Cotización
  registrarProveedor(enviar) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.cotizacion_url + 'registrarProveedor', enviar, { headers: headers });
  }
  //Componente Cotización
  buscarProveedores(buscar) {
    let params = new HttpParams();
		params = params.append('buscar', buscar);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.cotizacion_url + 'buscarProveedores', params, { headers: headers });
  }
  //Componente Cotización
  agregarCotizacionGeneral(entidad) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.cotizacion_url + 'agregarCotizacion', entidad, { headers: headers });
  }
  actualizarProductosCotizacion(enviar) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.cotizacion_url + 'actualizarProductosCotizacion', enviar, { headers: headers });
  }


  actualizarRequerimientoProductoCotizacionEstado(entidad) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.cotizacion_url + 'actualizarRequerimientoProductoCotizacionEstado', entidad, { headers: headers });
  }
  actualizarRequerimientoCotizacionEstado(entidad) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.cotizacion_url + 'actualizarRequerimientoCotizacionEstado', entidad, { headers: headers });
  }
  registrarVendedor(entidad) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.cotizacion_url + 'registrarVendedor', entidad, { headers: headers });
  }
  ordenDeCompraGenerarAutomatico(entidad) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.cotizacion_url + 'ordenDeCompraGenerarAutomatico', entidad, { headers: headers });
  }
  //Componente Requerimiento
  cotizacionesGeneralesModelo(page = null, env: any, estado:any, ordenes: any, codigo: any, requerimiento: any, mes: any, year: any) {
    if(page == null){
      page  = 1;
    }
    if(env == null){
      env  = 0;
    }
    if(estado == null){
      estado  = 0;
    }
    if(ordenes == null){
      ordenes  = 0;
    }
    if(codigo == null){
      codigo  = 0;
    }
    if(mes == null){
      mes  = 0;
    }
    if(year == null){
      year  = 0;
    }
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
      });
		return this._http.get(this.url + this.cotizacion_url + 'cotizacionesGeneralesModelo/'+page+'/'+env+'/'+estado+'/'+ordenes+'/'+codigo+'/'+requerimiento+'/'+mes+'/'+year,{headers});
  }
  //Componente ORDEN
  obtenerOrdenes(page = null, proveedor: any, orden:any, cotizacion: any, mes: any, year: any, empresa: any, estado: any, vendedor: any, orden_general: any) {
    if(page == null){
      page  = 1;
    }
    if(orden == null){
      orden  = 0;
    }
    if(cotizacion == null){
      cotizacion  = 0;
    }
    if(mes == null){
      mes  = 0;
    }
    if(year == null){
      year  = 0;
    }
    if(empresa == null){
      empresa  = 0;
    }
    if(estado == null){
      estado  = 0;
    }
    if(vendedor == null){
      vendedor  = 0;
    }
    if(orden_general == null){
      orden_general  = 0;
    }
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
      });
		return this._http.get(this.url + this.orden_url + 'obtenerOrdenes/'+page+'/'+proveedor+'/'+orden+'/'+cotizacion+'/'+mes+'/'+year+'/'+empresa+'/'+estado+'/'+vendedor+'/'+orden_general,{headers});
  }
  //Componente Orden
  buscarProveedoresOrden(buscar) {
    let params = new HttpParams();
    params = params.append('buscar', buscar);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.orden_url + 'buscarProveedores', params, { headers: headers });
  }
  //Componente Orden
  buscarVendedoresOrden(buscar) {
    let params = new HttpParams();
    params = params.append('buscar', buscar);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.orden_url + 'buscarVendedores', params, { headers: headers });
  }
  actualizarComprobantePagoOrdenes(enviar) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.orden_url + 'actualizarComprobantePagoOrdenes', enviar, { headers: headers });
  }
  comprobantesPagoAgregarActualizarGeneral(enviar) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.orden_url + 'comprobantesPagoAgregarActualizarGeneral', enviar, { headers: headers });
  }

  actualizarComprobantePagoEstadoPositivo(enviar) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.orden_url + 'actualizarComprobantePagoEstadoPositivo', enviar, { headers: headers });
  }
  //Componente Orden  
  anularNotaDeCreditoComprobates(enviar) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.orden_url + 'anularNotaDeCreditoComprobates', enviar, { headers: headers });
  }
  //Componente PROVEEDORES
  proveedores(page = null) {
    if(page == null){
      page  = 1;
    }
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
      });
		return this._http.get(this.url + this.proveedor_url + 'proveedores/'+page,{headers});
  }
  //Componente Orden  
  agregarProveedores(enviar) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.proveedor_url + 'agregar', enviar, { headers: headers });
  }
  agregarAutomaticoSinCotizacionOrdenCompra(enviar) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._usuSer.getToken()
    });
    return this._http.post(this.url + this.cotizacion_url + 'agregarAutomaticoSinCotizacionOrdenCompra', enviar, { headers: headers });
  }
 //Componente Orden  
 actualizarOrdenServiciosFinalizada(enviar) {
  let headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this._usuSer.getToken()
  });
  return this._http.post(this.url + this.orden_url + 'actualizarOrdenServiciosFinalizada', enviar, { headers: headers });
}
 //Componente Orden  
 eliminarOrdenGeneral(enviar) {
  let headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this._usuSer.getToken()
  });
  return this._http.post(this.url + this.orden_url + 'eliminarOrdenGeneral', enviar, { headers: headers });
}
}