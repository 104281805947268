import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AdminGuard } from './service/admin.guard';
export const AppRoutes: Routes = [{
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
}, {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    children: [{
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
    },{
        path: 'tesoreria',
        loadChildren: './components/tesoreria/tesoreria.module#TesoreriaModule'
    },{
        path: 'reportes',
        loadChildren: './components/reportes/reportes.module#ReportesModule'
    }, {
        path: 'administracion',
        loadChildren: './components/administracion/administracion.module#AdministracionModule'
    }, {
        path: 'compras',
        loadChildren: './components/compras/compras.module#ComprasModule'
    }, {
        path: 'almacen',
        loadChildren: './components/almacen/almacen.module#AlmacenModule'
    },  {
        path: '',
        loadChildren: './userpage/user.module#UserModule'
    }]
}, {
    path: '',
    component: AuthLayoutComponent,
    children: [{
        path: '',
        loadChildren: './components/login/pages.module#PagesModule'
    }]
}
];
