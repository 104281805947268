import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CommonModule } from '@angular/common';


import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';

import { AdminGuard } from './service/admin.guard';
import { UsuarioService } from './service/usuario.service';
import { PerfilService } from './service/perfil.service';
import { AdministracionService } from './service/administracion.service';
import { StorageService } from './service/storage.service';
import { ProductosService } from './service/productos.service';
import { VentasService } from './service/ventas.service';
import { ReportesService } from './service/reportes.service';
import { CompraService } from './service/compra.service';
import { AlmacenService } from './service/almacen.service';
import { TesoreriaService } from './service/tesoreria.service';

import { SidebarModule } from './sidebar/sidebar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
@NgModule({
    imports: [
        NgIdleKeepaliveModule.forRoot(),
        MomentModule,
        BrowserAnimationsModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        RouterModule.forRoot(AppRoutes, {
            useHash: true
        }),
        NgbModule,
        HttpModule,
        HttpClientModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        FixedPluginModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent
    ],
    providers: [TesoreriaService,ReportesService,AdministracionService, AdminGuard, UsuarioService, StorageService, PerfilService,  ProductosService, VentasService, CompraService, AlmacenService],
    bootstrap: [AppComponent]
})

export class AppModule { }
