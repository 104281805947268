import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
 constructor(private idle: Idle, private keepalive: Keepalive,  private router: Router) {
     /* idle.setIdle(60);
      idle.setTimeout(15);
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      idle.onIdleEnd.subscribe(() => {
          this.idleState = 'Click en el Boton continuar'
          this.reset();
      });
      idle.onTimeout.subscribe(() => {
          this.idleState = '¡Caducado!';
          this.timedOut = true;
          this.logout()
      });
      idle.onIdleStart.subscribe(() => {
          this.idleState = 'Te has quedado inactivo!'
          this.abrirModal();
      });
      idle.onTimeoutWarning.subscribe((countdown) => {
          this.idleState = 'Cuenta regresiva para cerrar el sistema ' + countdown + ' segundos!'
      });
      keepalive.interval(60);
      keepalive.onPing.subscribe(() => this.lastPing = new Date());
      this.reset();*/
  }
  reset() {
      this.idle.watch();
      this.timedOut = false;
  }
  stay() {
      $('#abrirModal').modal('hide');
      this.reset();
  }
  logout() {
      $('#abrirModal').modal('hide');
      $('.modal-backdrop').remove();
      localStorage.clear();
      this.router.navigate(['/login']);
  }
  abrirModal() {
      $('#abrirModal').modal('show');
  }
}
