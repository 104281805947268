import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';
import { UsuarioService } from './usuario.service'
import { forkJoin, timer,of ,fromEvent, partition, Observable, asyncScheduler } from 'rxjs';
import { take, mapTo, switchMap, catchError, mergeMap   } from 'rxjs/operators';
import { fromFetch } from 'rxjs/fetch';
@Injectable()
export class AdministracionService {
    public url: string;
    public identity: any;
    public token: any;
    public verify: any;
    public url_interna: any;
    public url_unidades: any
    constructor(private _http: HttpClient, private _usuSer: UsuarioService) {
        this.url = GLOBAL.url;
        this.url_interna = '/administracion/administracion/';
        this.url_unidades = '/administracion/unidades/';
    }
   
    
      inicioPruebaFork(){
        const source = forkJoin({
            todos: timer(500).pipe(mapTo([{ title: 'RxJS'}])),
            user: timer(500).pipe(mapTo({ id: 1 }))
          });
          source.subscribe({
            next({ todos, user }) { }
          });
      }
      inicioFork2(){
        forkJoin(
            [
            this._http.get('https://jsonplaceholder.typicode.com/posts'),
            this._http.get('https://jsonplaceholder.typicode.com/users')
            ]).subscribe(
              resp=> {
                { resp}
              },
              error => {
                console.log(error);
              }
          );
      }
      segundaPrueba(){
        const users$ = fromFetch('https://reqres.in/api/users').pipe(
            switchMap(response => {
              if (response.ok) {
                return response.json();
              } else {
                return of({ error: true, message: `Error ${response.status}` });
              }
            }),
            catchError((error) => of({ error: true, message: error.message }))
          );
      }

      registerUser() {
        this._http.get('https://jsonplaceholder.typicode.com/posts/1').pipe(
            mergeMap((res: any)=> 
                this._http.get('https://jsonplaceholder.typicode.com/users/'+res.userId))
                ).subscribe((authorDetails: any)=>{
                    console.log(authorDetails)
                })
      }


    obtenerIndexEstado(objecto: any, filtro: any): any {
        return objecto.findIndex(a => a.id == filtro);
    }
    //COMPONENTE ADMINISTRACION GENERAL CRUD
    obtener(model: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_interna + 'obtener', model, { headers: headers });
    }

    //COMPONENTE ADMINISTRACION GENERAL CRUD
    crud(model: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_interna + 'crud', model, { headers: headers });
    }
    //COMPONENTE EMPRESA CRUD
    crudEmpresa(model: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_interna + 'crudEmpresa', model, { headers: headers });
    }
     //COMPONENTE EMPRESA CRUD
     eliminar(model) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_interna + 'eliminar', model, { headers: headers });
    }
    //COMPONENTE EMPRESA CRUD
    eliminarEmpresa(id) {
        let params = new HttpParams();
		params = params.append('id_empresa', id);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_interna + 'eliminarEmpresa', params, { headers: headers });
    }
    //COMPONENTE MOVIMIENTO
    movimiento() {
        let params = new HttpParams();
        params = params.append('verificar', 'lorem');
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_interna + 'movimiento', params, { headers: headers });
    }
    
    //COMPONENTE ESTABLECIMIENTO
    establecimiento() {
        let params = new HttpParams();
        params = params.append('verificar', 'lorem');
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_interna + 'establecimiento', params, { headers: headers });
    }
    crudEstablecimiento(model: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_interna + 'crudEstablecimiento', model, { headers: headers });
    }
     //COMPONENTE EMPRESA CRUD
     eliminarEstablecimiento(model) {
        let params = new HttpParams();
        params = params.append('id', model);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_interna + 'eliminarEstablecimiento', params, { headers: headers });
    }
    //COMPONENTE TIPO DOCUMENTO
    unidadesObtener() {
        let params = new HttpParams();
        params = params.append('verificar', 'lorem');
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_unidades + 'index', params, { headers: headers });
    }
    crudUnidades(enviar) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_unidades + 'crudUnidades', enviar, { headers: headers });
    }
    eliminarUnidad(id) {
        let params = new HttpParams();
        params = params.append('id', id);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_unidades + 'eliminarUnidad', params, { headers: headers });
    }
    //Componente Orden
    anularPrimeraFaceOrdenCompra(enviar) {
        let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_interna + 'anularPrimeraFaceOrdenCompra', enviar, { headers: headers });
    }
    //Componente ORDEN
    obtenerOrdenes(page = null, proveedor: any, orden:any, cotizacion: any, mes: any, year: any, empresa: any, estado: any, vendedor: any, orden_general: any) {
        if(page == null){
        page  = 1;
        }
        if(orden == null){
        orden  = 0;
        }
        if(cotizacion == null){
        cotizacion  = 0;
        }
        if(mes == null){
        mes  = 0;
        }
        if(year == null){
        year  = 0;
        }
        if(empresa == null){
        empresa  = 0;
        }
        if(estado == null){
        estado  = 0;
        }
        if(vendedor == null){
        vendedor  = 0;
        }
        if(orden_general == null){
        orden_general  = 0;
        }
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
            });
            let uk = this.url + this.url_interna + 'obtenerOrdenes/'+page+'/'+proveedor+'/'+orden+'/'+cotizacion+'/'+mes+'/'+year+'/'+empresa+'/'+estado+'/'+vendedor+'/'+orden_general;
            return this._http.get(uk,{headers});
    }
    //Componente Orden   -- ANULAR
    actualizarOrdenGeneral(enviar) {
        let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_interna + 'actualizarEstadoOrdenes', enviar, { headers: headers });
    }
    inicio() {
        let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_interna + 'inicio', '', { headers: headers });
    }
    obtenerCotizacionesInternas() {
        let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this._usuSer.getToken()
        });
        return this._http.get(this.url + this.url_interna + 'obtenerCotizacionesInternas', { headers: headers });
    }
    actualizarCotizaciones(enviar) {
        let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + this.url_interna + 'actualizarCotizaciones', enviar, { headers: headers });
    }
}