import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';
import { UsuarioService } from './usuario.service'
@Injectable()
export class ReportesService {
	public url: string;
	constructor(private _http: HttpClient, private _usuSer: UsuarioService) {
		this.url = GLOBAL.url;
	}

	listarReportePrimero(fecha: any, empresa: any) {
		let params = new HttpParams();
		params = params.append('fecha', fecha);
		params = params.append('empresa_id', empresa);
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + '/reportes/reporte/reporteVentaEmpresa', params, { headers: headers });
	}
	obtenerReporteDiario() {
		let params = new HttpParams();
		params = params.append('any', 'any');
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + '/reportes/reporte/obtenerReporteDiario', params, { headers: headers });
	}
	reporteVentaComprobantes(fecha: any, empresa: any, medio: any) {
		let params = new HttpParams();
		params = params.append('fecha', fecha);
		params = params.append('empresa_id', empresa);
		params = params.append('tipo', medio);
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + '/reportes/reporte/reporteVentaComprobantes', params, { headers: headers });
	}
	reporteVentaComprobantesA(fecha: any, empresa: any, medio: any) {
		let params = new HttpParams();
		params = params.append('fecha', fecha);
		params = params.append('empresa_id', empresa);
		params = params.append('tipo', medio);
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + '/reportes/reporte/reporteVentaComprobantesExcel', params, { headers: headers });
	}
	reporteProductosDetallados(fecha: any, empresa: any) {
		let params = new HttpParams();
		params = params.append('fecha', fecha);
		params = params.append('empresa_id', empresa);
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + '/reportes/reporte/reporteProductosDetallados', params, { headers: headers });
	}
}