import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UsuarioService } from './usuario.service';
import Swal from 'sweetalert2';

declare var $: any;

@Injectable()
export class AdminGuard implements CanActivate {
	constructor(private _usuarioService: UsuarioService, private _router: Router) {

	}
	canActivate() {
		let identity = this._usuarioService.getToken();
		if (identity) {
			return true;
		} else {
			this.showSwal()
			this._router.navigate(['/login']);
			return false;

		}
	}
	showSwal() {
		Swal.fire({
			title: 'Error...!',
			text: 'No tiene permiso para ingresar',
			type: 'warning',
			buttonsStyling: false,
			confirmButtonClass: 'btn btn-info'
		})
	}
}