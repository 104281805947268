import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';
import { UsuarioService } from './usuario.service'
import { Requerimiento } from '../model/requerimiento';
import { Cliente } from '../model/cliente';
import { forkJoin, of  } from 'rxjs';
import {  catchError   } from 'rxjs/operators';
@Injectable()
export class VentasService {

	public url: string;
	public identity: any;
	public token: any;
	public verify: any;
	requeriminetos: Requerimiento[] = [];
	clientes: Cliente[] = [];
	constructor(private _http: HttpClient, private _usuSer: UsuarioService) {
		this.url = GLOBAL.url;
	}
	obtenerIndex(objecto: any, filtro: any): any {
		return objecto.findIndex(a => a.id === filtro);
	}

	obtenerIndexx(objecto: any, filtro: any): any {
		return objecto.findIndex(a => a.id == filtro);
	}


	buscarProductosVarios(buscar) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + '/pedidos/ventas/buscarProductosVentas', buscar, { headers: headers });
	}
	buscarProductosVentasRequerimientos(buscar) {
		let params = new HttpParams();
		params = params.append('buscar', buscar);
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + '/pedidos/ventas/buscarProductosVentasRequerimientos', params, { headers: headers });
	}
	getProducto() {
		if (localStorage.getItem('requerimientos') === null) {
		  this.requeriminetos = [];
		} else {
		  this.requeriminetos = JSON.parse(localStorage.getItem('requerimientos'));
		}
		return this.requeriminetos;
	}
	getCliente() {
		if (localStorage.getItem('clientes') === null) {
		  this.clientes = [];
		} else {
		  this.clientes = JSON.parse(localStorage.getItem('clientes'));
		}
		return this.clientes;
	}
	addProducto(requerimiento: Requerimiento) {
		this.requeriminetos.push(requerimiento);
		let productos = [];
		if (localStorage.getItem('requerimientos') === null) {
		  productos = [];
		  productos.push(requerimiento);
		  localStorage.setItem('requerimientos', JSON.stringify(productos));
		} else {
		  productos = JSON.parse(localStorage.getItem('requerimientos'));
		  productos.push(requerimiento);
		  localStorage.setItem('requerimientos', JSON.stringify(productos));
		}
	}
	addCliente(cliente: Cliente) {
		this.clientes.push(cliente);
		let clie = [];
		if (localStorage.getItem('clientes') === null) {
		  clie = [];
		  clie.push(cliente);
		  localStorage.setItem('clientes', JSON.stringify(clie));
		} else {
		  clie = JSON.parse(localStorage.getItem('clientes'));
		  clie.push(cliente);
		  localStorage.setItem('clientes', JSON.stringify(clie));
		}
	}
	deleteProducto(producto: Requerimiento) {
		for (let i = 0; i < this.requeriminetos.length; i++) {
		  if (producto === this.requeriminetos[i]) {
			this.requeriminetos.splice(i, 1);
			localStorage.setItem('requerimientos', JSON.stringify(this.requeriminetos));
		  }
		}
	}
	deleteCliente(cliente: Cliente) {
		for (let i = 0; i < this.clientes.length; i++) {
		  if (cliente === this.clientes[i]) {
			this.clientes.splice(i, 1);
			localStorage.setItem('clientes', JSON.stringify(this.clientes));
		  }
		}
	}
	buscarClienteEmpresa(dniruc) {
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': this._usuSer.getToken()});
		let enviar = {dni: dniruc}
		  return forkJoin({
			basedatos: this._http.post(this.url + '/pedidos/ventas/buscarCliente', dniruc, { headers: headers }).pipe(catchError(err => of ({isError: true, error: err}))),
			reniec: this._http.get("https://consultoriovasquez.com/datos/public/api/v1/ruc/"+dniruc+"?token=abcxyz").pipe(catchError(err => of ({isError: true, error: err})))
			});
	}
	buscarClientePersona(dniruc: any){
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': this._usuSer.getToken()});
		let enviar = {dni: dniruc["dni"]}
		  return forkJoin({
			basedatos: this._http.post(this.url + '/pedidos/ventas/buscarCliente', dniruc, { headers: headers }).pipe(catchError(err => of ({isError: true, error: err}))),
			reniec: this._http.get("https://consultoriovasquez.com/datos/public/api/v1/dni/"+dniruc+"?token=abcxyz").pipe(catchError(err => of ({isError: true, error: err})))
			})
      }
	generarBoletaoFactura(enviar) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + '/pedidos/ventas/ventasSalidaAlmacen', enviar, { headers: headers });
	}
	ventasSalidaAlmacenSinDocumento(enviar) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + '/pedidos/ventas/ventasSalidaAlmacenSinDocumento', enviar, { headers: headers });
	}
	generarLaSuma(enviar) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + '/pedidos/ventas/sumarTotales', enviar, { headers: headers });
	}
	obtenerResponder(enviar) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + '/pedidos/ventas/obtenerResponder', enviar, { headers: headers });
	}
	obtenerResponderCotizacion(enviar) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + '/pedidos/ventas/obtenerResponderCotizacion', enviar, { headers: headers });
	}
	generarCotizacionInterna(enviar: any) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this._usuSer.getToken()
		});
		return this._http.post(this.url + '/pedidos/ventas/generarCotizacionInterna', enviar, { headers: headers });
	}
}