import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';
import { UsuarioService } from './usuario.service'

@Injectable()
export class PerfilService {
    public url: string;
    public identity: any;
    public token: any;
    public verify: any;
    constructor(private _http: HttpClient, private _usuSer: UsuarioService) {
        this.url = GLOBAL.url;
    }
    //COMPONENTE USUARIO
    obtenerDatos() {
        let params = new HttpParams();
        params = params.append('verificar', 'lorem');
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + '/usuario/perfil/obtenerDatos', params, { headers: headers });
    }
    //COMPONENTE USUARIO
    actualizarDatos(nombre_completo) {
        let params = new HttpParams();
        params = params.append('nombre_completo', nombre_completo);
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + '/usuario/perfil/actualizarDatos', params, { headers: headers });
    }
    //COMPONENTE USUARIO
    actualizarUsuario(password) {
        let params = new HttpParams();
        params = params.append('password', password);
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this._usuSer.getToken()
        });
        return this._http.post(this.url + '/usuario/perfil/actualizarUsuario', params, { headers: headers });
    }
}